.htd__navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky; top:0;
    padding: 2rem 6rem;
    background-color: #ebc500;
}


.htd__navbar-links_container {
    display: flex;
    flex-direction: row;

}

.htd__navbar-links_container p,
.htd__navbar-menu_container p {
    color: black;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    text-transform: capitalize;
    border: rgba(255, 255, 255, 0.603) solid 1px;
    box-shadow: 2px 4px #888888;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0 1rem;
    cursor: pointer;
}

.htd__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.htd__navbar-menu svg {
    cursor: pointer;
}

.htd__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: rgb(69, 69, 75);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.htd__navbar-menu_container p {
    margin: 1rem 0;
}
