.about__icons {
    text-align: center;
    padding-top: 0.5rem;
}

.about__icons img{
    margin: 2rem;
    max-width: 10%;
    
}

@media screen and (max-width: 550px) {
    .contact__title h1{
        font-size: 36px;
    }
    .contact__title a{
        font-size: 20px;
    }
    .about__icons img{
        max-width: 15%;
    }
    
}