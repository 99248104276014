.htd__topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
}

.htd__topbar-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
}

.htd__topbar-logo h1{
    font-family: Impact;
    font-weight: 1000;
    padding-left: 1rem;
    word-spacing: 0.3rem;
}

.htd__topbar-logo img {
    max-width: 15%;
}

.htd__topbar-info {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
}

.htd__topbar-info a {
    padding: 0.5rem;
}

@media screen and (max-width: 1050px){
    .htd__topbar-logo h2{
        font-size: 22px;
    }
    .htd__topbar-logo img{
        max-width: 20%;
    }
    
}

@media screen and (max-width: 550px){
    .htd__topbar-logo h1{
        font-size: 22px;
    }
    .htd__topbar-logo img{
        max-width: 25%;
    }
    .htd__topbar-info a{
        font-size: 12px;
    }
}