.htd__about {
    background-color: rgb(12,44,148);
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 1rem;
    
}

.htd__about h1 {
    font-size: 42px;
    padding: 2rem;
    font-family: var(--font-family);
    color: white;
}
.htd__about p{
    font-family: var(--font-family);
    font-size: 25px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: white;
}

.htd__about img {
    max-width: 80%;
    border: 5px solid blue;
    border-radius: 25px;
    
}

.htd__about a{
    display: flex;
    justify-content: center;
    margin: 2rem;
    font-family: var(--font-family);
    color: white;
    font-size: 24px;
    font-weight: 500;
}

@media screen and (max-width: 770px) {

    .htd__about h1{
        font-size: 36px;
    }
    .htd__about p{
        font-size: 18px;
    }
    .htd__about a{
        font-size: 18px;
    }
}