.htd__header{
    height: 400px;
    background-image: url('../../assets/headerimg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.htd__header-content {
    display: flex;
    background-color: rgba(0, 0, 0, 0.700);
    justify-content: center;
    padding: 3rem;
    border-radius: 5px;
    max-width: 33%
}
.htd__header-content p{
    font-family: var(--font-family);
    color: white;
    font-size: 36px;
    text-align: center;
    font-weight: 300;

}

@media  screen and (max-width: 1050px) {
    .htd__header-content {
        max-width: 70%;
        padding: 2rem;
    }
}

@media screen and (max-width: 770px) {
    .htd__header-content p{
        font-size: 24px;
    }
    
}