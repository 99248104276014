@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ff1100 1.84%, #0104b4 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ff1100 -13.86%, #0104b4 99.55%);
  
  --color-bg: #ffffff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}